body,html,#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  /*white-space: pre-line;*/
}

body {
  margin: 0;
  font-family: Rajdhani, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: rgba(255, 0, 0, 0);
  padding: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #3a3a3a;
  border-radius: 3.5px;
  padding: 5px;
  width: 12px;
}
